<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="darken_yellow">
        <v-toolbar-title><h4 class="font-weight-light black--text">FROM LOAN SHARE CAPITAL</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="account_no"
                  label="CMF No"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="account_name"
                  label="Account Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-col></v-col>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="amount"
                      label="Amount to Capital"
                      dense
                      outlined
                      type="number"
                      min="1"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="depositors_name"
                      label="Depositors Name"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert
                    color="warning"
                    text
                    class="mb-0"

                  >
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{alert_message}}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    class="me-3 mt-4"
                    @click="save_share_capital_application"
                    v-if="!saving"
                  >
                    Save changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="justify-center position-relative"
              >
                <v-data-table
                  class="pt-4"
                  :height="height"
                  :headers="headers"
                  :items="list_of_new_member"
                  :search="search"
                  :loading="data_table_loading"
                  loading-text="Loading... Please wait"
                  dense
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr
                      @click="activerow(item)"
                      :class="{'info black--text': item.id===selectedDepositId}"
                    >
                      <td> {{list_of_new_member.map(function(x) {return x.id;
                        }).indexOf(item.id)+1}}
                      </td>
                      <td> {{item.members.last_name+', '+item.members.first_name
                        +' '+item.members.middle_name}}
                      </td>
                      <td> {{item.share_capital}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],
        list_of_new_member: [],

        account_id: '',
        account_no: '',
        account_name: '',
        amount: 0,
        depositors_name: '',

        selectedDepositId: -1,

        data_table_loading: false,
        headers: [
          {text: 'No', value: 'no'},
          {text: 'Name', value: 'full_name'},
          {text: 'Amount', value: 'share_capital'},
        ],
        height: 430
      }
    },
    mounted() {
      this.reset()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'user_id', 'branch', 'month_start', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loans_data', ['list_of_loans_is_pending_sc', 'save_sc']),
      ...mapActions('share_capital', ['register_share_capital_dep']),

      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.account_id = 0
        this.account_no = ''
        this.account_name = ''
        this.depositors_name = ''

        this.amount = 0
        this.list_of_loans_is_pending_sc({
          branch_id: this.branch_id
        })
          .then(response => {
            this.list_of_new_member = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      activerow(value) {
        this.selectedDepositId = value.id;
        this.account_no = value.members.cmf_no
        this.account_name = value.members.last_name + ', ' + value.members.first_name + ' ' + value.members.middle_name
        this.depositors_name = value.members.last_name + ', ' + value.members.first_name + ' ' + value.members.middle_name
        this.amount = value.share_capital
        this.account_id = value.members.id
      },
      save_share_capital_application() {
        this.saving = true
        this.alert = false
        // var flag = false
        // if (moment().format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
        //   || moment().format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
        //   flag = true
        // } else {
        //   var compareDate = moment()
        //   var startDate = moment(this.month_start, 'MMMM D, YYYY')
        //   var endDate = moment(this.month_end, 'MMMM D, YYYY')
        //
        //   flag = compareDate.isBetween(startDate, endDate) //false in this case
        // }
        // if (flag === false) {
        //   this.alert = true
        //   this.alert_message = 'Date Accomplished is Not Belong in Transaction Month!'
        //   this.saving = false
        // } else {
        var proceed = true;
        if (proceed) {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('member_id', this.account_id);
            data.append('amount', this.amount);
            data.append('month_of', this.month_of);
            data.append('is_from_loan', false);
            this.register_share_capital_dep(data)
              .then(response => {
                this.save_sc({
                  id: this.selectedDepositId
                })
                this.print_deposit_slip(response.data.id, moment().format('MMMM DD, YYYY'), moment().format('hh:mm:ss'), response.data.branch_id)
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: this.account_name + ' SHARE CAPITAL SAVED !!!',
                })
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
        // }
      },
      print_deposit_slip(id, dateeee, time, reg_id) {
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {text: 'Share Capital, Cash Deposit', style: 'printed_label'},
            {text: 'REFDEP' + id, style: 'printed_label'},
            {text: this.account_no, style: 'printed_label'},
            {text: this.account_name, style: 'printed_label'},
            {text: 'Effective:  ' + moment().add("year", 1).format('YYYY'), style: 'printed_label'},
            {text: 'PHP ' + this.amount, style: 'printed_label'},
            {text: 'SHARE CAPITAL', style: 'printed_label'},
            {text: dateeee + ' ' + time, style: 'printed_label'},
            {text: 'SC: ' + reg_id, style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'printed_label'},
            {text: 'Depositor: ' + this.depositors_name.toUpperCase(), style: 'printed_label'},
          ],
          styles: {
            printed_label: {
              color: 'red',
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
          }
        }
        pdfMake.createPdf(docDefinition).open();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }
</style>
